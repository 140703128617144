<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/valid-v-bind -->
<template>
  <div>
    <documentTabs />
    <router-view />
    <b-row>
      <b-col>
        <b-card class="text-center">
          <b-link
            href="#terms"
            @click.prevent="termsModal = true"
          >
            <span
              style="font-size:small;"
              class="btn-link"
            >{{ $t('Agreement_register_page.terms').toUpperCase() }}</span>
          </b-link>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="termsModal"
      :title="$t('Agreement_register_page.terms').toUpperCase()"
      size="lg"
      ok-only
    >
      <div
        dir="ltr"
        style="text-align: left;"
        v-html="termsText"
      />
      <template #modal-footer>
        <button
          v-show="false"
        />
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BCol, BModal, BRow,
} from 'bootstrap-vue'
import moment from 'moment/moment'
import documentTabs from './documentTabs.vue'
import DescriptionApis from '@/services/apis/description'

const description = new DescriptionApis()
export default {
  components: {
    BModal,
    BRow,
    BCard,
    BCol,
    documentTabs,
  },
  data() {
    return {
      termsModal: false,
      termsText: '',
    }
  },
  async mounted() {
    moment.locale(this.$i18n.locale)
    await description.get({ name: 'agreement-register' }).then(res => {
      this.termsText = res.data.results.value
    })
  },
}
</script>
